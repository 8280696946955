import React, {useEffect, useRef, useState} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Spinner,
    TabPane,
    TabContent,
    NavItem,
    NavLink,
    Nav,
    Label,
    Input,
    Container
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "./datatables.scss";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getGames, getTeam} from "../../slices/Games/thunk";
import Timer from "../../Timer";
import addNotification from 'react-push-notification';
import * as constant from '../../constants/global';

// @ts-ignore
import NotificationSound from '../../assets/sounds/notification-sound.mp3';
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import Select from "react-select";
import {addCompetitionFilter, removeCompetitionFilter} from "../../slices/Layout/thunk";
import {getStrategyAll} from "../../slices/Strategy/thunk";
import {round} from "lodash";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const GamesTable = (props: any) => {

    const audioPlayer = useRef(null);

    const dispatch = useDispatch();

    const [page, setPage] = useState(1)

    const [position, setPosition] = useState(0);

    const [gameClosed, setGameClosed] = useState(0);
    const [forceLoading, setForceLoading] = useState(true);
    const [notificationSend, setNotificationSend] = useState(['s'])
    const [gameModal, setGameModal] = useState(false);
    const [displayListTeam, setDisplayListTeam] = useState(true);

    // @ts-ignore
    const [favoriteGames, setFavoriteGame] = useState(localStorage.getItem(constant.favorite_games) != null ? JSON.parse(localStorage.getItem(constant.favorite_games)) : [])
    const [favoriteGamesFilter, setFavoriteGamesFilterState]
        = useState(localStorage.getItem(constant.favorite_games_filter) == '1')

    const [gameModalData, setGameModalData] = useState({
        competition: '',
        time_game: 0,
        extra_time_duration: 0,
        status: '',
        home: '',
        away: '',
        home_score: 0,
        home_score_status: '',
        away_score: 0,
        away_score_status: '',
        home_goals: '',
        away_goals: '',
        home_corner: 0,
        home_corner_status: '',
        away_corner: 0,
        away_corner_status: '',
        home_shots: '',
        away_shots: '',
        home_possession: 0,
        home_possession_status: '',
        away_possession: 0,
        away_possession_status: '',
        home_red_card: 0,
        away_red_card: 0,
        home_yellow_card: 0,
        away_yellow_card: 0,
        home_shots_status: '',
        away_shots_status: '',
        home_initial_odd: '',
        away_initial_odd: '',
        home_current_odd: '',
        away_current_odd: '',
        initial_draw_odd: '',
        current_draw_odd: '',
        game_event_id: '',
        game_william_hill: ''
    });

    const [defaultTabModal, setDefaultTabModal] = useState("1");

    const [homeScore, setHomeScore] = useState(localStorage.getItem(constant.home_score_filter) == null ? '' : localStorage.getItem(constant.home_score_filter))
    const [awayScore, setAwayScore] = useState(localStorage.getItem(constant.away_score_filter) == null ? '' : localStorage.getItem(constant.away_score_filter))

    const [timeStartScore, setTimeStartScore] = useState(localStorage.getItem(constant.time_score_start_filter) == null ? '' : localStorage.getItem(constant.time_score_start_filter))
    const [timeEndScore, setTimeEndScore] = useState(localStorage.getItem(constant.time_score_end_filter) == null ? '' : localStorage.getItem(constant.time_score_end_filter))

    const [timeStart, setTimeStart] = useState(localStorage.getItem(constant.time_start_filter) == null ? '' : localStorage.getItem(constant.time_start_filter))
    const [timeEnd, setTimeEnd] = useState(localStorage.getItem(constant.time_end_filter) == null ? '' : localStorage.getItem(constant.time_end_filter))

    // @ts-ignore
    const [homeScoreStatus, setHomeScoreStatus] = useState(localStorage.getItem(constant.home_score_status_filter) != null ? JSON.parse(localStorage.getItem(constant.home_score_status_filter)) : null)
    // @ts-ignore
    const [awayScoreStatus, setAwayScoreStatus] = useState(localStorage.getItem(constant.away_score_status_filter) != null ? JSON.parse(localStorage.getItem(constant.away_score_status_filter)) : null)

    const [totalGoals, setTotalGoals] = useState(localStorage.getItem(constant.total_goals_filter) != null ? localStorage.getItem(constant.total_goals_filter) : '')
    const [goalsExact, setGoalsExact] = useState({home: '', away:''})

    const [team, setTeam] = useState('')

    // @ts-ignore
    const [totalGoalsStatus, setTotalGoalsStatus] = useState(localStorage.getItem(constant.total_goals_status_filter) != null ? JSON.parse(localStorage.getItem(constant.total_goals_status_filter)) : null)

    const [dateTimeStart, setDateTimeStart] = useState(localStorage.getItem(constant.date_start_filter) == null ? '' : localStorage.getItem(constant.date_start_filter))
    const [dateTimeEnd, setDateTimeEnd] = useState(localStorage.getItem(constant.date_end_filter) == null ? '' : localStorage.getItem(constant.date_end_filter))


    const [searchDateTime, setSearchDateTime] = useState('')
    const [submitFilterDate, setSubmitFilterDate] = useState(true)
    const [submitFilterCompetition, setSubmitFilterCompetition] = useState('')

    const [hiOddStart, setHiOddStart] = useState(localStorage.getItem(constant.odds_filter + '_hi_start'))
    const [hcOddStart, setHcOddStart] = useState(localStorage.getItem(constant.odds_filter + '_hc_start'))

    const [hiOddEnd, setHiOddEnd] = useState(localStorage.getItem(constant.odds_filter + '_hi_end'))
    const [hcOddEnd, setHcOddEnd] = useState(localStorage.getItem(constant.odds_filter + '_hc_end'))

    const [aiOddStart, setAiOddStart] = useState(localStorage.getItem(constant.odds_filter + '_ai_start'))
    const [acOddStart, setAcOddStart] = useState(localStorage.getItem(constant.odds_filter + '_ac_start'))

    const [aiOddEnd, setAiOddEnd] = useState(localStorage.getItem(constant.odds_filter + '_ai_end'))
    const [acOddEnd, setAcOddEnd] = useState(localStorage.getItem(constant.odds_filter + '_ac_end'))


    // @ts-ignore
    const [hiOddStartStatus, setHiOddStartStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_hi_start') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_hi_start')) : null)

    // @ts-ignore
    const [hiOddEndStatus, setHiOddEndStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_hi_end') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_hi_end')) : null)


    // @ts-ignore
    const [hcOddStartStatus, setHcOddStartStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_hc_start') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_hc_start')) : null)

    // @ts-ignore
    const [hcOddEndStatus, setHcOddEndStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_hc_end') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_hc_end')) : null)


    // @ts-ignore
    const [aiOddStartStatus, setAiOddStartStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_ai_start') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_ai_start')) : null)

    // @ts-ignore
    const [aiOddEndStatus, setAiOddEndStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_ai_end') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_ai_end')) : null)


    // @ts-ignore
    const [acOddStartStatus, setAcOddStartStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_ac_start') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_ac_start')) : null)

    // @ts-ignore
    const [acOddEndStatus, setAcOddEndStatus] = useState(localStorage.getItem(constant.odds_filter_status + '_ac_end') != null ? JSON.parse(localStorage.getItem(constant.odds_filter_status + '_ac_end')) : null)

    const [displayGameFilter, setDisplayGameFilter] = useState(localStorage.getItem(constant.display_game_filter) == null ? '0' : localStorage.getItem(constant.display_game_filter))

    const [teamSearch, setTeamSearch] = useState(localStorage.getItem(constant.team_search) ?? '')

    const [role, setRole] = useState(2)

    const playAudio = () => {
        if(audioPlayer.current !== null){
            // @ts-ignore
            audioPlayer.current.play();
        }
    }

    const sendNotification  = (titleIn: string, messageIn: string) => {
        addNotification({
            title: titleIn,
            message: messageIn,
            duration: 30000,
            native: true,
            silent: false
        })
    }

    const callTimeValue = (time_game: number)=> {
        return parseInt(String(time_game/60)) + ':' + ((time_game%60) < 10 ? '0' + (time_game%60) : (time_game%60))
    }

    const checkFavoriteGame = (event: string, star: boolean) => {

        if(!favoriteGamesFilter && !star){
            return true
        }
        // @ts-ignore
        let favorite_game_set = JSON.parse(localStorage.getItem(constant.favorite_games))

        if(favorite_game_set == null || favorite_game_set.length == 0){
            return !star;

        }else{
            const index_array = favorite_game_set.indexOf(event)
            return index_array > -1;
        }
    }

    const {games, loading, competitionFilter, competitionFilterColors, teams, loadingTeam,
        desktopGoalPrediction, desktopGoalPrediction_15, desktopGoalPrediction_15_30, desktopGoalPrediction_30_45,
        desktopGoalPrediction_45_60, desktopGoalPrediction_60_75, desktopGoalPrediction_75} = useSelector((state: any) => ({
        games: state.games.games.length > 0 && state.Layout.desktopNotification !='' ?
            state.games.games.map( (game: any, key: any) =>
            {
                const labelNotification = game ? game.id + '_' + game.home_score + '_' + game.visitant_score : ''
                // @ts-ignore
                if(game && checkFavoriteGame(game.home + ' - ' + game.visitant, false) && state.Layout.desktopNotification == 'active' && game.home_score != null && notificationSend.indexOf(labelNotification) == -1 && game.game_closed === '0'){

                    if(game.visitant_score_previous != game.visitant_score || game.home_score_previous != game.home_score){
                        const title = game.home_initial_odd + ' ' + game.home + ' - ' + game.visitant + ' ' + game.visitant_initial_odd;

                        const message = 'Goal ' + game.home_score_previous  + '-' + game.visitant_score_previous + ' => ' + game.home_score  + '-' + game.visitant_score + ' ' + callTimeValue(parseInt(game.time_game))
                        sendNotification(title, message)
                        notificationSend.push(labelNotification)
                        // @ts-ignore
                        setNotificationSend(notificationSend)
                        playAudio()
                    }
                }

                //setPosition(1)
                // @ts-ignore
                return ({
                    'id': game.id,
                    'position': key + 1,
                    'competition': game.competition,
                    'home_corner_previous': parseInt(game.home_corner_previous),
                    'home_corner': parseInt(game.home_corner),

                    'g_15_home': game.g_15_home,
                    'g_15_30_home': game.g_15_30_home,
                    'g_30_45_home': game.g_30_45_home,
                    'g_45_60_home': game.g_45_60_home,
                    'g_60_75_home': game.g_60_75_home,
                    'g_75_90_home': game.g_75_90_home,

                    'g_15_home_total': game.g_15_home_total,
                    'g_15_30_home_total': game.g_15_30_home_total,
                    'g_30_45_home_total': game.g_30_45_home_total,
                    'g_45_60_home_total': game.g_45_60_home_total,
                    'g_60_75_home_total': game.g_60_75_home_total,
                    'g_75_90_home_total': game.g_75_90_home_total,

                    'g_15_away': game.g_15_away,
                    'g_15_30_away': game.g_15_30_away,
                    'g_30_45_away': game.g_30_45_away,
                    'g_45_60_away': game.g_45_60_away,
                    'g_60_75_away': game.g_60_75_away,
                    'g_75_90_away': game.g_75_90_away,

                    'g_15_away_total': game.g_15_away_total,
                    'g_15_30_away_total': game.g_15_30_away_total,
                    'g_30_45_away_total': game.g_30_45_away_total,
                    'g_45_60_away_total': game.g_45_60_away_total,
                    'g_60_75_away_total': game.g_60_75_away_total,
                    'g_75_90_away_total': game.g_75_90_away_total,

                    'home_possession_previous': parseInt(game.home_possession_previous),
                    'home_possession': parseInt(game.home_possession),

                    'home_attack_percentage_previous': (parseInt(game.home_dangerous_attack_previous) > 0 && parseInt(game.home_dangerous_attack_previous) > 0 ?
                        round(parseInt(game.home_dangerous_attack_previous) * 100 / (parseInt(game.home_dangerous_attack_previous) + parseInt(game.home_attack_previous)),
                            1) : 0),

                    'home_attack_percentage': (parseInt(game.home_dangerous_attack) > 0 && parseInt(game.home_dangerous_attack) > 0 ?
                        round(parseInt(game.home_dangerous_attack) * 100 / (parseInt(game.home_dangerous_attack) + parseInt(game.home_attack)),
                            1) : 0),
                    'home_red_card': parseInt(game.home_red_card),
                    'home_yellow_card': parseInt(game.home_yellow_card),

                    'home_shot_in_previous': parseInt(game.home_shot_in_previous),
                    'home_shot_in': parseInt(game.home_shot_in),

                    'home_shot_off_previous': parseInt(game.home_shot_off_previous),
                    'home_shot_off': parseInt(game.home_shot_off),
                    'home_series_result': game.home_series_result,
                    'home_series_goal_score': game.home_series_goal_score,
                    'home_series_goal_taken': game.home_series_goal_taken,
                    'home': game.home,
                    'goals_home': game.goals_home,

                    'home_score_previous': parseInt(game.home_score_previous),
                    'home_score': parseInt(game.home_score),

                    'time_game': parseInt(game.time_game),
                    'extra_time_duration': game.extra_time_duration,
                    'status': game.status,
                    'game_closed': game.game_closed,

                    'visitant_score_previous': parseInt(game.visitant_score_previous),
                    'visitant_score': parseInt(game.visitant_score),

                    'goals_away': game.goals_away,

                    'visitant_series_goal_score': game.visitant_series_goal_score,
                    'visitant_series_goal_taken': game.visitant_series_goal_taken,
                    'visitant_series_result': game.visitant_series_result,
                    'visitant': game.visitant,

                    'away_shot_in_previous': parseInt(game.away_shot_in_previous),
                    'away_shot_in': parseInt(game.away_shot_in),

                    'away_shot_off_previous': parseInt(game.away_shot_off_previous),
                    'away_shot_off': parseInt(game.away_shot_off),

                    'away_red_card': parseInt(game.away_red_card),
                    'away_yellow_card': parseInt(game.away_yellow_card),

                    'away_corner_previous': parseInt(game.away_corner_previous),
                    'away_corner': parseInt(game.away_corner),

                    'away_possession_previous': parseInt(game.away_possession_previous),
                    'away_possession': parseInt(game.away_possession),

                    'away_attack_percentage_previous': (parseInt(game.away_dangerous_attack_previous) > 0 && parseInt(game.away_dangerous_attack_previous) > 0 ?
                        round(parseInt(game.away_dangerous_attack_previous) * 100 / (parseInt(game.away_dangerous_attack_previous) + parseInt(game.away_attack_previous)),
                            1) : 0),

                    'away_attack_percentage': (parseInt(game.away_dangerous_attack) > 0 && parseInt(game.away_dangerous_attack) > 0 ?
                        round(parseInt(game.away_dangerous_attack) * 100 / (parseInt(game.away_dangerous_attack) + parseInt(game.away_attack)),
                            1) : 0),

                    'updated_at': game.updated_at,
                    'home_initial_odd': game.home_initial_odd,
                    'home_current_odd': game.home_current_odd,
                    'draw_initial_odd': game.draw_initial_odd,
                    'draw_current_odd': game.draw_current_odd,
                    'visitant_initial_odd': game.visitant_initial_odd,
                    'visitant_current_odd': game.visitant_current_odd,
                    'both_yes_score_initial_odd': game.both_yes_score_initial_odd,
                    'both_yes_score_current_odd': game.both_yes_score_current_odd,
                    'both_no_score_initial_odd': game.both_no_score_initial_odd,
                    'both_no_score_current_odd': game.both_no_score_current_odd,
                    'event_id': game.event_id,
                    'william_hill': game.william_hill
                });
            }
            ).filter(
                function (game: any){
                    return checkFavoriteGame(game.home + ' - ' + game.visitant, false)
                }
            )
            : [],
        error: state.games.error,
        loading: state.games.loading,

        competitionFilter: JSON.parse(state.Layout.competitionFilter),
        competitionFilterColors: state.Layout.competitionFilterColors,

        teams: state.games.teams ?
            state.games.teams.map( (team: any) =>
                {
                    return (team.team);
                }
            )
            : [],

        loadingTeam: state.games.loadingTeam,

        desktopGoalPrediction: state.Layout.desktopGoalPrediction,

        desktopGoalPrediction_15: state.Layout.desktopGoalPrediction_15,
        desktopGoalPrediction_15_30: state.Layout.desktopGoalPrediction_15_30,
        desktopGoalPrediction_30_45: state.Layout.desktopGoalPrediction_30_45,
        desktopGoalPrediction_45_60: state.Layout.desktopGoalPrediction_45_60,
        desktopGoalPrediction_60_75: state.Layout.desktopGoalPrediction_60_75,
        desktopGoalPrediction_75: state.Layout.desktopGoalPrediction_75,

    }));

    const setGameClosedClick = (gameClosedInput: number) => {
        setDefaultTabModal("1")
        setGoalsExact({home: '', away: ''})
        setForceLoading(true)
        setGameClosed(gameClosedInput)
        setFavoriteGamesFilter(false)
    }

    const setFavoriteGamesFilter = (favorite: boolean) => {
        setFavoriteGamesFilterState(favorite)
        setPage(1)
    }

    const dispatchGetGame = ()=>{
        dispatch(getGames({game_closed: gameClosed, home_score: homeScore, away_score: awayScore,
            time_start_score: timeStartScore, time_end_score:timeEndScore,
            time_start: timeStart, time_end: timeEnd,
            home_score_status: homeScoreStatus, away_score_status: awayScoreStatus,
            goals: totalGoals, goals_status: totalGoalsStatus,
            goals_exact: goalsExact,

            hi_start: hiOddStart,
            hi_end: hiOddEnd,
            ai_start: aiOddStart,
            ai_end: aiOddEnd,

            hc_start: hcOddStart,
            hc_end: hcOddEnd,
            ac_start: acOddStart,
            ac_end: acOddEnd,

            hi_start_status: hiOddStartStatus,
            hi_end_status: hiOddEndStatus,
            ai_start_status: aiOddStartStatus,
            ai_end_status: aiOddEndStatus,

            hc_start_status: hcOddStartStatus,
            hc_end_status: hcOddEndStatus,
            ac_start_status: acOddStartStatus,
            ac_end_status: acOddEndStatus,

            start_updated_at: dateTimeStart,
            end_updated_at: dateTimeEnd,
            competition: competitionFilter,
            team: team
        }))
    }
    const updateGameTableTimeout = () =>{
        setForceLoading(false)
        dispatchGetGame();
    }

    //game table effects
    useEffect(() => {
        dispatch(getStrategyAll());
        dispatchGetGame();
        const timer = setInterval(updateGameTableTimeout, 30000);
        return () => clearInterval(timer);
    }, [gameClosed, homeScore, awayScore, timeStartScore, timeEndScore, timeStart,
              timeEnd, homeScoreStatus, awayScoreStatus, totalGoals, totalGoalsStatus, searchDateTime,
              submitFilterCompetition, goalsExact,
              hiOddStart, hiOddStartStatus, hiOddEnd, hiOddEndStatus,
              hcOddStart, hcOddStartStatus, hcOddEnd, hcOddEndStatus,
              aiOddStart, aiOddStartStatus, aiOddEnd, aiOddEndStatus,
              acOddStart, acOddStartStatus, acOddEnd, acOddEndStatus,
              team, favoriteGamesFilter
    ])

    useEffect(() => {
        dispatch(getTeam({team: teamSearch}));
    }, [teamSearch])

    useEffect(() => {
        localStorage.setItem(constant.favorite_games_filter, (favoriteGamesFilter ? '1': '0'))
    }, [favoriteGamesFilter])

    useEffect(() => {
        const getAuthUser = localStorage.getItem("authUser");

        if (getAuthUser) {
            const obj = JSON.parse(getAuthUser);
            if(obj.user_name){
                setRole(obj.roles[0].role);
            }

        }
    },[])

    const rowClasses = (row: any) => {
      if(row.home_score > row.visitant_score){
          return 'row-home-winner' + (row.game_closed == "1" ? '-closed' : '');
      }
      if(row.home_score < row.visitant_score){
          return 'row-home-loser' + (row.game_closed == "1" ? '-closed' : '');
      }
      return '';
    }

    const columns = [
    { text: 'id', dataField: 'id', hidden: true},
    { text: '#',  dataField: 'position', align: 'center', headerAlign: 'center', hidden: !gameClosed},
    { text: props.t('Competition'), dataField: 'competition', align: 'center', headerAlign: 'center', sort:false},
    { text: 'Home Red card', dataField: 'home_red_card', hidden: true},
    { text: props.t('Home'), dataField: 'home', sort: false, classes: 'w-cell-team',
        //eslint-disable-next-line react/display-name
        formatter: (cellContent: any, row: any) => (
            <Col>
                {
                    !gameClosed && row.home_series_result != null && row.home_series_result != '' &&
                    <React.Fragment>
                        <Row className="result-col-max-width text-primary">
                            {
                                row.home_series_result != null &&
                                row.home_series_result.split("").map((val: any, i: any) =>
                                    <Col key={'home_result_' + row.id + '_' + i}>
                                        {val == 'D'  ? <span className="bx bx-xs bxs-minus-circle"></span> :
                                         val == 'W'  ? <span className="clr-green bx bx-xs bxs-check-circle"></span> :
                                                       <span className="clr-red bx bx-xs bxs-x-circle"></span>
                                        }
                                    </Col>
                                )
                            }
                        </Row>
                        <Row className="result-col-max-width text-primary">
                            {
                                row.home_series_goal_score != null &&
                                row.home_series_goal_score.split("").map((val: any, i: any) =>
                                    <Col key={'home_score_' + row.id + '_' + i}>{val}</Col>
                                )
                            }
                        </Row>
                        <Row  className="result-col-max-width text-primary">
                            {   row.home_series_goal_taken != null &&
                                row.home_series_goal_taken.split("").map((val: any, i: any) =>
                                    <Col key={'home_taken_' + row.id + '_' + i}>{val}</Col>
                                )
                            }
                        </Row>
                    </React.Fragment>
                }
                <Row>
                    <Col>
                        {row.home}
                        {' '}
                        {
                            row.home_series_goal_score != null && row.home_series_goal_score != ''  && row.game_closed == '0' && blinking(row.status, row.time_game, row.g_15_home, row.g_15_30_home, row.g_30_45_home, row.g_45_60_home, row.g_60_75_home, row.g_75_90_home) &&
                            <span className="blink bx bx-football"></span>
                        }
                        {
                            eval(row.home_initial_odd) <= 0.6 && //very favorite
                            row.home_score <= row.visitant_score &&
                            <React.Fragment>
                                {' '} <i key={'satisfaction_home_' + row.id} className="uil-annoyed clr-red"></i>
                            </React.Fragment>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="text-black">
                        {row.home_red_card > 0 &&
                            <React.Fragment>
                                {[...Array(row.home_red_card)].map((x, i) =>
                                    <i key={'card_home_' + i} className="mdi mdi-checkbox-blank clr-red"></i>
                                )}
                            </React.Fragment>
                        }

                        (<span className={(row.home_shot_in_previous + row.home_shot_off_previous) < (row.home_shot_in + row.home_shot_off) ? 'clr-green' :
                        (row.home_shot_in_previous + row.home_shot_off_previous) > (row.home_shot_in + row.home_shot_off)  ? 'clr-red' : ''}>
                               {row.home_shot_in + '/' + row.home_shot_off}
                        </span>)

                        (<span className={row.home_corner_previous < row.home_corner ? 'clr-green' :
                        row.home_corner_previous > row.home_corner ? 'clr-red' : ''}>
                               {row.home_corner}
                        </span>)

                        ({
                           <span className={row.home_attack_percentage_previous < row.home_attack_percentage ? 'clr-green' :
                                     row.home_attack_percentage_previous > row.home_attack_percentage ? 'clr-red' : ''}>
                               {row.home_attack_percentage + '%'}
                           </span>
                         })

                        ({
                        <span className={row.home_possession_previous < row.home_possession ? 'clr-green' :
                                         row.home_possession_previous > row.home_possession ? 'clr-red' : ''}>
                               {row.home_possession + '%'}
                        </span>
                        })
                    </Col>
                </Row>
                {
                    !gameClosed && row.home_series_result != null && row.home_series_result != '' &&
                    <React.Fragment>
                        <Row className="text-primary">
                            <Col>
                                <Row className={row.g_15_home == "0" && row.g_15_30_home == "0" && row.g_30_45_home == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_15_home}</Col><Col>{row.g_15_30_home}</Col><Col>{row.g_30_45_home}</Col>
                                </Row>
                            </Col>
                             -
                            <Col>
                                <Row className={row.g_45_60_home == "0" && row.g_60_75_home == "0" && row.g_75_90_home == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_45_60_home}</Col><Col>{row.g_60_75_home}</Col><Col>{row.g_75_90_home}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="text-primary">
                            <Col>
                                <Row className={row.g_15_home_total == "0" && row.g_15_30_home_total == "0" && row.g_30_45_home_total == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_15_home_total - row.g_15_home}</Col>
                                    <Col>{row.g_15_30_home_total - row.g_15_30_home}</Col>
                                    <Col>{row.g_30_45_home_total - row.g_30_45_home}</Col>
                                </Row>
                            </Col>
                            -
                            <Col>
                                <Row className={row.g_45_60_home_total == "0" && row.g_60_75_home_total == "0" && row.g_75_90_home_total == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_45_60_home_total - row.g_45_60_home}</Col>
                                    <Col>{row.g_60_75_home_total - row.g_60_75_home}</Col>
                                    <Col>{row.g_75_90_home_total - row.g_75_90_home}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </Col>
        )
    },
    { text: props.t('Goals'), dataField: 'goals_home', align: 'center', headerAlign: 'center', sort: false},
    { text: '  ', dataField: 'home_score', sort: false, align: 'center',
        //eslint-disable-next-line react/display-name
        formatter: (cellContent: any, row: any) => (
                <span>{row.home_score}{row.home_score !== row.home_score_previous && row.game_closed == 0 &&
                <i className="uil-football"></i>
                }</span>

        )
    },
    { text: props.t('Time'), dataField: 'time_game', sort: true, align: 'center', headerAlign: 'center', hidden: !!gameClosed,
        //eslint-disable-next-line react/display-name
        formatter: (cellContent: any, row: any) => (
            row.game_closed === '1' ?
            'FT' :
            <span>
                <span>
                    {
                        row.extra_time_duration != '0' ? row.extra_time_duration : ''
                    }
                </span>
                <Timer initialSeconds={row.time_game} halfTime={row.status} >
                </Timer>
            </span>

        )
    },
    { text: '', dataField: 'game_closed', hidden: true},
    { text: '', dataField: 'status', sort: false, align: 'center', headerAlign: 'center', hidden: !!gameClosed},
    { text: '  ', dataField: 'visitant_score', sort: false, align: 'center',
        //eslint-disable-next-line react/display-name
        formatter: (cellContent: any, row: any) => (
            <span>{row.visitant_score}{row.visitant_score !== row.visitant_score_previous && gameClosed == 0 &&
                <i className="uil-football"></i>
            }</span>

        )

    },
    { text: props.t('Goals'), dataField: 'goals_away', align: 'center', headerAlign: 'center', sort: false},
    { text: props.t('Away'), dataField: 'visitant', sort: false, classes: 'w-cell-team',
        //eslint-disable-next-line react/display-name
        formatter: (cellContent: any, row: any) => (
            <Col>
                {
                    !gameClosed && row.visitant_series_result != null && row.visitant_series_result != '' &&
                    <React.Fragment>
                        <Row className="result-col-max-width text-primary">
                            {
                                row.visitant_series_result != null &&
                                row.visitant_series_result.split("").map((val: any, i: any) =>
                                    <Col key={'visitant_result_' + row.id + '_' + i}>
                                        {val == 'D'  ? <span className="bx bx-xs bxs-minus-circle"></span> :
                                            val == 'W'  ? <span className="clr-green bx bx-xs bxs-check-circle"></span> :
                                                <span className="clr-red bx bx-xs bxs-x-circle"></span>
                                        }
                                    </Col>
                                )
                            }
                        </Row>
                        <Row className="result-col-max-width text-primary">
                            {   row.visitant_series_goal_score != null &&
                                row.visitant_series_goal_score.split("").map((val: any, i: any) =>
                                    <Col key={'visitant_score_' + row.id + '_' + i}>{val}</Col>
                                )
                            }
                        </Row>
                        <Row  className="result-col-max-width text-primary">
                            {   row.visitant_series_goal_taken != null &&
                                row.visitant_series_goal_taken.split("").map((val: any, i: any) =>
                                    <Col key={'visitant_taken_' + row.id + '_' + i}>{val}</Col>
                                )
                            }
                        </Row>
                    </React.Fragment>
                }
                <Row>
                    <Col>
                        {row.visitant}
                        {' '}
                        {
                            row.visitant_series_goal_score != null && row.visitant_series_goal_score != '' && row.game_closed == '0' && blinking(row.status, row.time_game, row.g_15_away, row.g_15_30_away, row.g_30_45_away, row.g_45_60_away, row.g_60_75_away, row.g_75_90_away) &&
                            <span className="blink bx bx-football"></span>
                        }
                        {
                            eval(row.visitant_initial_odd) <= 0.6 && //very favorite
                            row.home_score >= row.visitant_score &&
                            <React.Fragment>
                                {' '} <i key={'satisfaction_away_' + row.id} className="uil-annoyed clr-red"></i>
                            </React.Fragment>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="text-black">
                        {row.away_red_card > 0 &&
                            <React.Fragment>
                                {[...Array(row.away_red_card)].map((x, i) =>
                                    <i key={'card_away_' + i} className="mdi mdi-checkbox-blank clr-red"></i>
                                )}
                            </React.Fragment>
                        }

                        (<span className={(row.away_shot_in_previous + row.away_shot_off_previous) < (row.away_shot_in + row.away_shot_off) ? 'clr-green' :
                        (row.away_shot_in_previous + row.away_shot_off_previous) > (row.away_shot_in + row.away_shot_off)  ? 'clr-red' : ''}>
                               {row.away_shot_in + '/' + row.away_shot_off}
                        </span>)

                        (<span className={row.away_corner_previous < row.away_corner ? 'clr-green' :
                        row.away_corner_previous > row.away_corner ? 'clr-red' : ''}>
                               {row.away_corner}
                        </span>)

                        (<span className={row.away_attack_percentage_previous < row.away_attack_percentage ? 'clr-green' :
                        row.away_attack_percentage_previous > row.away_attack_percentage ? 'clr-red' : ''}>
                               {row.away_attack_percentage + '%'}
                        </span>)

                        (<span className={row.away_possession_previous < row.away_possession ? 'clr-green' :
                                          row.away_possession_previous > row.away_possession ? 'clr-red' : ''}>
                               {row.away_possession + '%'}
                        </span>)
                    </Col>
                </Row>
                {
                    !gameClosed && row.visitant_series_result != null && row.visitant_series_result != '' &&
                    <React.Fragment>
                        <Row className="text-primary">
                            <Col>
                                <Row className={row.g_15_away == "0" && row.g_15_30_away == "0" && row.g_30_45_away == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_15_away}</Col><Col>{row.g_15_30_away}</Col><Col>{row.g_30_45_away}</Col>
                                </Row>
                            </Col>
                            -
                            <Col>
                                <Row className={row.g_45_60_away == "0" && row.g_60_75_away == "0" && row.g_75_90_away == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_45_60_away}</Col><Col>{row.g_60_75_away}</Col><Col>{row.g_75_90_away}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="text-primary">
                            <Col>
                                <Row className={row.g_15_away_total == "0" && row.g_15_30_away_total == "0" && row.g_30_45_away_total == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_15_away_total - row.g_15_away}</Col>
                                    <Col>{row.g_15_30_away_total - row.g_15_30_away}</Col>
                                    <Col>{row.g_30_45_away_total - row.g_30_45_away}</Col>
                                </Row>
                            </Col>
                            -
                            <Col>
                                <Row className={row.g_45_60_away_total == "0" && row.g_60_75_away_total == "0" && row.g_75_90_away_total == "0" ? 'clr-red' : 'text-primary'}>
                                    <Col>{row.g_45_60_away_total - row.g_45_60_away}</Col>
                                    <Col>{row.g_60_75_away_total - row.g_60_75_away}</Col>
                                    <Col>{row.g_75_90_away_total - row.g_75_90_away}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </Col>
        )
    },
    { text: props.t('Closed at'), dataField: 'updated_at', sort: true, hidden: !gameClosed},
    { text: 'Event id', dataField: 'event_id', hidden: true, sort:false},
    { text: 'H I', dataField: 'home_initial_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'H C', dataField: 'home_current_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'D I', dataField: 'draw_initial_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'D C', dataField: 'draw_current_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'A I', dataField: 'visitant_initial_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'A C', dataField: 'visitant_current_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'BTS I Yes', dataField: 'both_yes_score_initial_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'BTS C Yes', dataField: 'both_yes_score_current_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'BTS I No', dataField: 'both_no_score_initial_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'BTS C No', dataField: 'both_no_score_current_odd', align: 'center', headerAlign: 'center', sort:false},
    { text: 'markets', dataField: 'markets', hidden: true, sort:false},
    { text: 'outcomes', dataField: 'outcomes', hidden: true, sort:false},
    { text: 'shot_minute', dataField: 'shot_minute', hidden: true, sort:false}];

    const pageOptions: any = {
        page: page,
        sizePerPage: 10,
        totalSize: games.length, // replace later with size(customers),
        custom: true,
    };

    // Select All Button operation
    const selectRow: any = {
        mode: "checkbox",
    };

    const { SearchBar } = Search;

    const rowEvents = {

        onClick: (e: any, row: any) => {

            setGameModalData({
                competition: row.competition,
                extra_time_duration: row.extra_time_duration,
                time_game: row.time_game,
                status: row.status,
                home: row.home,
                away: row.visitant,
                home_score: row.home_score,
                home_score_status: row.home_score > row.visitant_score ? ' clr-green' : row.home_score < row.visitant_score ? ' clr-red' : '',
                away_score: row.visitant_score,
                away_score_status: row.home_score < row.visitant_score ? ' clr-green' : row.home_score > row.visitant_score ? ' clr-red' : '',
                home_goals: row.goals_home,
                away_goals: row.goals_away,
                home_corner: row.home_corner,
                home_corner_status: row.home_corner > row.away_corner ? ' clr-green' : row.home_corner < row.away_corner ? ' clr-red' : '',
                away_corner: row.away_corner,
                away_corner_status: row.home_corner < row.away_corner ? ' clr-green' : row.home_corner > row.away_corner ? ' clr-red' : '',
                home_shots: row.home_shot_in + '/' + row.home_shot_off,
                away_shots: row.away_shot_in + '/' + row.away_shot_off,
                home_possession: row.home_possession,
                home_possession_status: row.home_possession > row.away_possession ? ' clr-green' : row.home_possession < row.away_possession ? ' clr-red' : '',
                away_possession: row.away_possession,
                away_possession_status: row.home_possession < row.away_possession ? ' clr-green' : row.home_possession > row.away_possession ? ' clr-red' : '',
                home_red_card: row.home_red_card,
                away_red_card: row.away_red_card,
                home_yellow_card: row.home_yellow_card,
                away_yellow_card: row.away_yellow_card,
                home_shots_status: (row.home_shot_in + row.home_shot_off) > (row.away_shot_in + row.away_shot_off) ?
                    'clr-green' : (row.home_shot_in + row.home_shot_off) < (row.away_shot_in + row.away_shot_off) ? 'clr-red' : '',
                away_shots_status: (row.home_shot_in + row.home_shot_off) < (row.away_shot_in + row.away_shot_off) ?
                    'clr-green' : (row.home_shot_in + row.home_shot_off) > (row.away_shot_in + row.away_shot_off) ? 'clr-red' : '',
                home_initial_odd: row.home_initial_odd,
                away_initial_odd: row.visitant_initial_odd,
                home_current_odd: row.home_current_odd,
                away_current_odd: row.visitant_4current_odd,
                initial_draw_odd: row.draw_initial_odd,
                current_draw_odd: row.draw_current_odd,
                game_event_id: row.event_id,
                game_william_hill: row.william_hill
            })

            setDefaultTabModal("1");
            //@ts-ignore
            setGameModal(true)
        }
    };

    const defaultToggleModal = (tab: any) => {
        if (defaultTabModal !== tab) setDefaultTabModal(tab);
    };

    const filterScore = (team: string, event: any) => {

        if(event.target.value > -1){

            setForceLoading(true)

            if(team === 'home'){
                setHomeScore(event.target.value)
                localStorage.setItem(constant.home_score_filter, event.target.value)
            }

            if(team === 'away'){
                setAwayScore(event.target.value)
                localStorage.setItem(constant.away_score_filter, event.target.value)

            }

        }

    }

    const clearScore = () => {
        setHomeScore('')
        setAwayScore('')
        localStorage.setItem(constant.home_score_filter, '')
        localStorage.setItem(constant.away_score_filter, '')
    }

    const filterTimeScore = (time: string, event: any) => {

        if(event.target.value > -1){

            setForceLoading(true)

            if(time === 'start'){
                setTimeStartScore(event.target.value)
                localStorage.setItem(constant.time_score_start_filter, event.target.value)
            }

            if(time === 'end'){
                setTimeEndScore(event.target.value)
                localStorage.setItem(constant.time_score_end_filter, event.target.value)
            }

        }

    }

    const clearTimeScore = () => {
        setTimeStartScore('')
        setTimeEndScore('')
        localStorage.setItem(constant.time_score_start_filter, '')
        localStorage.setItem(constant.time_score_end_filter, '')
    }

    const filterTime = (time: string, event: any) => {

        if(event.target.value > -1){

            setForceLoading(true)

            if(time === 'start'){
                setTimeStart(event.target.value)
                localStorage.setItem(constant.time_start_filter, event.target.value)
            }

            if(time === 'end'){
                setTimeEnd(event.target.value)
                localStorage.setItem(constant.time_end_filter, event.target.value)
            }

        }

    }

    const clearTime = () => {
        setTimeStart('')
        setTimeEnd('')
        localStorage.setItem(constant.time_start_filter, '')
        localStorage.setItem(constant.time_end_filter, '')
        setSubmitFilterCompetition(Date())
    }

    const clearTotalGoals = () => {
        setTotalGoals('')
        localStorage.setItem(constant.total_goals_filter, '')
    }

    const listStatus = [
        { label: '=', value: 'equal'},
        { label: '>', value: 'greater'},
        { label: '<', value: 'less'}
    ]

    const listTotalGoalsStatus = [
        { label: '=', value: 'equal'},
        { label: '>', value: 'greater'},
        { label: '<', value: 'less'},
        { label: '!=', value: 'diff'}
    ]

    const setScoreStatusTeam = (team: string, row: any) => {
        setForceLoading(true)
        if(team === 'home'){
            setHomeScoreStatus(row)
            localStorage.setItem(constant.home_score_status_filter, JSON.stringify(row))
        }

        if(team === 'away'){
            setAwayScoreStatus(row)
            localStorage.setItem(constant.away_score_status_filter, JSON.stringify(row))
        }
    }

    const filterTotalGoals = (event: any) =>{
        if(event.target.value > -1){
            setForceLoading(true)
            setTotalGoals(event.target.value)
            localStorage.setItem(constant.total_goals_filter, event.target.value)
        }
    }

    const filterTotalGoalsStatus = (row: any) =>{
        setForceLoading(true)
        setTotalGoalsStatus(row)
        localStorage.setItem(constant.total_goals_status_filter, JSON.stringify(row))
    }

    const filterGoalsHomeExact = (home: any, away: any) =>{
        setGoalsExact({home: home, away: away})
        setGameModal(false)
        setGameClosed(1)
    }

    const filterTeam = (team: any, closedGame: any = 0) =>{
        setDisplayListTeam(false)

        setFavoriteGamesFilter(false)

        setTeam(team)
        setGameModal(false)

        if(closedGame == 1){
            setForceLoading(closedGame)
            setGameClosed(closedGame)
        }

        setTeamSearch(team)
    }

    const filterDateTime = (when: string, event: any) => {

        let disableOK = false;
        if(when === 'start'){
            setDateTimeStart(event.target.value)
            localStorage.setItem(constant.date_start_filter, event.target.value)
            if(event.target.value == '' && dateTimeEnd == ''){
                disableOK = true;
            }
        }

        if(when === 'end'){
            setDateTimeEnd(event.target.value)
            localStorage.setItem(constant.date_end_filter, event.target.value)
            if(event.target.value == '' && dateTimeStart == ''){
                disableOK = true;
            }
        }

        setSubmitFilterDate(disableOK)

    }

    const clearFilterDate = ()=>{
        setDateTimeStart('')
        setDateTimeEnd('')
        setSubmitFilterDate(true)
        localStorage.setItem(constant.date_start_filter, '')
        localStorage.setItem(constant.date_end_filter, '')
        setSearchDateTime(Date())
    }

    const setCompetitionFilter = (competition: string) => {
        dispatch(addCompetitionFilter(competition));
    }

    const setFavoriteFilter = (event: string) => {

        // @ts-ignore
        let favorite_game_set = JSON.parse(localStorage.getItem(constant.favorite_games))

        if(event == ''){
            setFavoriteGame(false)
            localStorage.setItem(constant.favorite_games, JSON.stringify([]))
        }else
        if(favorite_game_set == null){
            localStorage.setItem(constant.favorite_games, JSON.stringify([event]))
            setFavoriteGame([event])
        }else{

            const index_array = favorite_game_set.indexOf(event)
            if(index_array > -1){
                favorite_game_set.splice(index_array, 1)
            }else{
                favorite_game_set.push(event)
            }
            setFavoriteGame(favorite_game_set)
            localStorage.setItem(constant.favorite_games, JSON.stringify(favorite_game_set))
        }
    }

    const removeCompetition = (competition: string) => {
        dispatch(removeCompetitionFilter(competition));
        setForceLoading(true)
        setSubmitFilterCompetition(Date())
    }

    const submitFilterCompetitionAct = () => {
        setForceLoading(true)
        setSubmitFilterCompetition(Date())
    }

    const filterOdd = (event: any, team: string, when: string) =>{
        if(event.target.value > -1){
            setForceLoading(true)
            if(team == 'hi'){

                if(when == 'start'){
                    setHiOddStart(event.target.value);
                }else{
                    setHiOddEnd(event.target.value);
                }

            }

            if(team == 'ai'){

                if(when == 'start'){
                    setAiOddStart(event.target.value);
                }else{
                    setAiOddEnd(event.target.value);
                }

            }

            if(team == 'hc'){

                if(when == 'start'){
                    setHcOddStart(event.target.value);
                }else{
                    setHcOddEnd(event.target.value);
                }

            }

            if(team == 'ac'){

                if(when == 'start'){
                    setAcOddStart(event.target.value);
                }else{
                    setAcOddEnd(event.target.value);
                }

            }

            localStorage.setItem(constant.odds_filter + '_' + team + '_' + when, event.target.value)
        }
    }

    const filterOddStatus = (row: any, team: string, when: string) =>{
        setForceLoading(true)

        if(team == 'hi'){

            if(when == 'start'){
                setHiOddStartStatus(row)
            }else{
                setHiOddEndStatus(row)
            }

        }

        if(team == 'ai'){
            if(when == 'start'){
                setAiOddStartStatus(row)
            }else{
                setAiOddEndStatus(row)
            }
        }

        if(team == 'hc'){

            if(when == 'start'){
                setHcOddStartStatus(row)
            }else{
                setHcOddEndStatus(row)
            }

        }

        if(team == 'ac'){
            if(when == 'start'){
                setAcOddStartStatus(row)
            }else{
                setAcOddEndStatus(row)
            }
        }

        localStorage.setItem(constant.odds_filter_status + '_' + team + '_' + when, JSON.stringify(row))
    }

    const clearOdd = (team: string, when: string) => {

        if(team == 'hi'){

            if(when == 'start'){
                setHiOddStart('');
            }else{
                setHiOddEnd('');
            }

        }

        if(team == 'hc'){

            if(when == 'start'){
                setHcOddStart('');
            }else{
                setHcOddEnd('');
            }

        }

        if(team == 'ai'){

            if(when == 'start'){
                setAiOddStart('');
            }else{
                setAiOddEnd('');
            }

        }

        if(team == 'ac'){

            if(when == 'start'){
                setAcOddStart('');
            }else{
                setAcOddEnd('');
            }

        }

        localStorage.setItem(constant.odds_filter + '_' + team + '_' + when,'')
    }

    const setDisplayFilter = () => {
        setDisplayGameFilter(displayGameFilter == '0' ? '1' : '0')
        localStorage.setItem(constant.display_game_filter, displayGameFilter == '0' ? '1' : '0')
    }

    const setTeamValue = (event: any) => {
        setDisplayListTeam(true)
        setTeamSearch(event.target.value)
        setFavoriteGamesFilter(false)
        localStorage.setItem(constant.team_search, event.target.value)

    }

    const clearTeamValue = () => {
        setTeam('')
        setTeamSearch('')
        localStorage.removeItem(constant.team_search)

    }

    const blinking = (status: any, time_game: any, g_15: any, g_15_30: any, g_30_45: any, g_45_60: any, g_60_75: any, g_75_90: any) => {

        const minute = parseInt(String(time_game / 60))
        return desktopGoalPrediction =='active' && (
            desktopGoalPrediction_15 =='active' && g_15 == "0" && minute< 16 ||
            desktopGoalPrediction_15_30 =='active' && g_15_30 == "0" && minute > 15 && minute < 31 ||
            desktopGoalPrediction_30_45 =='active' && g_30_45 == "0" && minute > 30 && status == '1H' ||

            desktopGoalPrediction_45_60 =='active' && g_45_60 == "0" && minute > 45 && minute < 61 && status == '2H' ||
            desktopGoalPrediction_60_75 =='active' && g_60_75 == "0" && minute > 60 && minute < 76 ||
            desktopGoalPrediction_75 =='active' && g_75_90 == "0" && minute > 75 && minute)
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Games")} | {props.t("Top Bet Football")}</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.t("Dashboards")} breadcrumbItem={props.t("Games")} />

                    <Card className="card-h-100">
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                columns={columns}
                                data={forceLoading && loading ? [] : games}
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <div className="d-flex flex-wrap gap-2">
                                          {
                                              favoriteGames != null && favoriteGames.length > 0 &&
                                              favoriteGames.map((x: any, i: any) => {

                                                      return <div key={'favoriteGames' + i}  className={'alert alert-' + competitionFilterColors[i%competitionFilterColors.length] + ' alert-dismissible fade show'} role="alert">
                                                          <button onClick={ ()=> setFavoriteFilter(x)} type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                          {x}</div>

                                                  }
                                              )
                                          }
                                          {
                                              favoriteGames != null && favoriteGames.length > 0 &&
                                              <React.Fragment>
                                                  <Button onClick={()=> setFavoriteGamesFilter(!favoriteGamesFilter)}
                                                          className="btn btn-primary mb-3" disabled={loading && forceLoading} type="button" color="primary">
                                                      {props.t(favoriteGamesFilter ? "Ignore favorites" : "Filter by favorites")}
                                                  </Button>
                                                  <Button onClick={()=> setFavoriteFilter('')}
                                                      className="btn btn-danger mb-3" disabled={loading && forceLoading} type="button" color="primary">
                                                      {props.t("Close all favorites")}
                                                  </Button>
                                              </React.Fragment>
                                          }
                                      </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex flex-wrap gap-2">
                                            {
                                                competitionFilter.map((x: any, i: any) => {

                                                    return <div key={'competitionFilter_' + i}  className={'alert alert-' + competitionFilterColors[i%competitionFilterColors.length] + ' alert-dismissible fade show'} role="alert">
                                                                <button onClick={ ()=> removeCompetition(x)} type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                            {x}</div>

                                                    }
                                                )
                                            }
                                            {
                                                competitionFilter.length > 0 &&
                                                <React.Fragment>
                                                    <Button onClick={()=> submitFilterCompetitionAct()} className="btn btn-sm mb-3" disabled={loading && forceLoading} type="button" color="primary">
                                                        {props.t("Search by competition")}
                                                    </Button>
                                                    <Button onClick={()=> removeCompetition('')} className="btn btn-danger btn-sm mb-3" disabled={loading && forceLoading} type="button" color="primary">
                                                        {props.t("Close all competitions")}
                                                    </Button>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <Button onClick={()=> setDisplayFilter()} type="button" color="primary">{props.t(displayGameFilter == '0' ? "Open filter" : "Close filter")} </Button>
                                        </Col>
                                        <Col>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Input placeholder={props.t("Search team")}
                                                           onChange={(event)=> setTeamValue(event)}
                                                           value={teamSearch}></Input>
                                                </Col>
                                                <Col>
                                                    <Button  onClick={()=> clearTeamValue()} type="button" color="primary">{props.t( "Clear")}</Button>
                                                </Col>
                                            </Row>
                                            {
                                                teamSearch != '' && displayListTeam &&
                                                <Row className="w-50">
                                                    <Col>
                                                        {
                                                            teams.length > 0 && !loadingTeam &&
                                                            <ul className="list-group position-absolute bg-light p-3 overflow-scroll"
                                                                style={{maxHeight: "300px", zIndex: 5000}}
                                                            >
                                                            {
                                                                teams.map((x : any, i: number) => {
                                                                    return <i onClick={()=> filterTeam(x)} key={'team_' + i}  className="text-secondary cursor-pointer">
                                                                        {x}
                                                                    </i>

                                                                })


                                                            }
                                                            </ul>
                                                        }

                                                        {

                                                            loadingTeam &&
                                                            <ul style={{zIndex: 5000}} className="list-group position-absolute bg-light p-3">
                                                                <i className="fas fa-spinner fa-spin"></i>
                                                            </ul>
                                                        }

                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                      {
                                          displayGameFilter == '1' &&
                                          <React.Fragment>
                                            <Row className="mb-3">
                                                <div className="filter-block-group">
                                                    <Row className="mb-1">
                                                        <Col xs={5} className="pe-0">
                                                            <Label htmlFor="home-score" className="col-form-label">{props.t("Home score")}</Label>
                                                            <Input onChange={(event)=> filterScore('home', event)} className="form-control text-center" value={homeScore??''} type="number" id="home-score" />
                                                        </Col>
                                                        <Col xs={5}  className="ps-1">
                                                            <Label htmlFor="away-score" className="col-form-label">{props.t("Away score")}</Label>
                                                            <Input onChange={(event)=> filterScore('away', event)} className="form-control text-center" value={awayScore??''} type="number" id="away-score" />
                                                        </Col>
                                                        <Col className="ps-0">
                                                            <i onClick={()=>clearScore()}
                                                               className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={5} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={homeScoreStatus}
                                                                onChange={ (row: any)=> {setScoreStatusTeam('home', row)}}
                                                            />
                                                        </Col>
                                                        <Col xs={5} className="ps-1">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={awayScoreStatus}
                                                                onChange={ (row: any)=> {setScoreStatusTeam('away', row)}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {
                                                    gameClosed == 0 &&
                                                    <div className="filter-block-group-2">
                                                        <Row className="mb-1">
                                                            <Col xs={8} className="pe-0">
                                                                <Label htmlFor="time-start" className="col-form-label">{props.t("Time start")}</Label>
                                                                <Input onChange={(event)=> filterTime('start', event)} className="form-control text-center" value={timeStart ?? ''} type="number" id="time-start" />
                                                            </Col>
                                                            <Col>
                                                                <i onClick={()=>clearTime()} className="mdi mdi-close-box text-danger filter-clear"></i>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={8} className="pe-0">
                                                                <Input onChange={(event)=> filterTime('end', event)} className="form-control text-center" value={timeEnd ?? ''} type="number" id="time-end" />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                                <div className="filter-block-group-3">
                                                    <Row className="mb-1">
                                                        <Col xs={9} className="pe-0">
                                                            <Label htmlFor="time-start-score" className="col-form-label">{props.t("Time score start")}</Label>
                                                            <Input onChange={(event)=> filterTimeScore('start', event)} className="form-control text-center" value={timeStartScore ?? ''} type="number" id="time-start-score" />
                                                        </Col>
                                                        <Col className="pe-0">
                                                            <i onClick={()=> clearTimeScore()} className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={9} className="pe-0">
                                                            <Input onChange={(event)=> filterTimeScore('end', event)} className="form-control text-center" value={timeEndScore ?? ''} type="number" id="time-end-score" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="total-goals" className="col-form-label">{props.t("Total goals")}</Label>
                                                            <Input onChange={(event)=> filterTotalGoals(event)} className="form-control text-center" value={totalGoals ?? ''} type="number" id="total-goals" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearTotalGoals()} className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listTotalGoalsStatus}
                                                                value={totalGoalsStatus}
                                                                onChange={ (row: any)=> {filterTotalGoalsStatus(row)}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="filter-block-group-4">
                                                    <Row className="mb-1">
                                                        <Col xs={12}>
                                                            <Row>
                                                                <Col>
                                                                    <Label htmlFor="date-time-start" className="col-form-label">{props.t("Filter dates")}</Label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={8}>
                                                                    <Input className="form-control"
                                                                           onChange={(event)=> filterDateTime('start', event)}
                                                                           type="datetime-local" value={dateTimeStart??''} id="date-time-start" />
                                                                </Col>
                                                                <Col xs={4} className="ps-0">
                                                                    <Button disabled={submitFilterDate} onClick={ ()=> setSearchDateTime(Date()) } type="button" color="primary">{props.t("OK")}</Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8}>
                                                            <Input className="form-control"
                                                                   onChange={(event)=> filterDateTime('end', event)}
                                                                   type="datetime-local" value={dateTimeEnd??''} id="date-time-end" />
                                                        </Col>
                                                        <Col xs={4} className="ps-0">
                                                            <Button onClick={ ()=> clearFilterDate() } type="button" color="danger">{props.t("X")}</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                            <Row className="mb-2">
                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="hi-odd" className="col-form-label">{props.t("HI start")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'hi', 'start')}
                                                                   className="form-control text-center" value={hiOddStart ?? ''} type="number" id="hi-odd" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('hi', 'start')} className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={hiOddStartStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'hi', 'start')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="hi-odd-end" className="col-form-label">{props.t("HI end")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'hi', 'end')}
                                                                   className="form-control text-center" value={hiOddEnd ?? ''} type="number" id="hi-odd-end" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('hi', 'end')}
                                                               className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={hiOddEndStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'hi', 'end')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="ai-odd-start" className="col-form-label">{props.t("AI start")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'ai', 'start')}
                                                                   className="form-control text-center" value={aiOddStart ?? ''} type="number" id="ai-odd-start" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('ai', 'start')} className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={aiOddStartStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'ai', 'start')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="ai-odd-end" className="col-form-label">{props.t("AI end")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'ai', 'end')}
                                                                   className="form-control text-center" value={aiOddEnd ?? ''} type="number" id="ai-odd-end" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('ai', 'end')}
                                                               className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={aiOddEndStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'ai', 'end')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>


                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="hc-odd-start" className="col-form-label">{props.t("HC start")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'hc', 'start')}
                                                                   className="form-control text-center" value={hcOddStart ?? ''} type="number" id="hc-odd" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('hc', 'start')} className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={hcOddStartStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'hc', 'start')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="hc-odd-end" className="col-form-label">{props.t("HC end")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'hc', 'end')}
                                                                   className="form-control text-center" value={hcOddEnd ?? ''} type="number" id="hc-odd-end" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('hc', 'end')}
                                                               className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={hcOddEndStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'hc', 'end')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="ac-odd-start" className="col-form-label">{props.t("AC start")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'ac', 'start')}
                                                                   className="form-control text-center" value={acOddStart ?? ''} type="number" id="ac-odd-start" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('ac', 'start')} className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={acOddStartStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'ac', 'start')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="filter-block-group-2">
                                                    <Row className="mb-1">
                                                        <Col xs={8} className="pe-0">
                                                            <Label htmlFor="ac-odd-end" className="col-form-label">{props.t("AC end")}</Label>
                                                            <Input onChange={(event)=> filterOdd(event, 'ac', 'end')}
                                                                   className="form-control text-center" value={acOddEnd ?? ''} type="number" id="ac-odd-end" />
                                                        </Col>
                                                        <Col>
                                                            <i onClick={()=> clearOdd('ac', 'end')}
                                                               className="mdi mdi-close-box text-danger filter-clear"></i>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={8} className="pe-0">
                                                            <Select
                                                                isSearchable={false}
                                                                className="select-filter"
                                                                classNamePrefix="react-select"
                                                                placeholder="="
                                                                options={listStatus}
                                                                value={acOddEndStatus}
                                                                onChange={ (row: any)=> {filterOddStatus(row, 'ac', 'end')}}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                          </React.Fragment>
                                      }
                                    <Row className="mb-2">
                                        <Col>
                                            {!gameClosed &&
                                                <Button className="w-100" disabled={loading && forceLoading} type="button" color="primary">{props.t("Open games")}</Button>
                                            }
                                            { gameClosed == 1 &&
                                                <Button className="w-100" disabled={loading && forceLoading} onClick={() => setGameClosedClick(0)} type="button" outline color="primary">{props.t("Open games")}</Button>
                                            }
                                        </Col>
                                        <Col>
                                            {!gameClosed &&
                                                <Button className="w-100" disabled={loading && forceLoading}  onClick={() => setGameClosedClick(1)} type="button" outline color="primary">{props.t("Closed games")}</Button>
                                            }
                                            { gameClosed == 1 &&
                                                <Button className="w-100" disabled={loading && forceLoading}  type="button" color="primary">{props.t("Closed games")}</Button>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                  <Col xl="12">
                                   <div className="table-responsive">
                                      <BootstrapTable
                                        //responsive
                                        bordered={true}
                                        striped={true}
                                        //hover={true}
                                        rowEvents={rowEvents}
                                        noDataIndication={loading ? props.t("Loading games") : props.t("No games found")}
                                        rowClasses={rowClasses}
                                        //selectRow={selectRow}
                                        classes={"table align-middle table-nowrap row-result"}
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                       <div className="d-inline">
                                           <SizePerPageDropdownStandalone
                                               {...paginationProps}
                                           />
                                       </div>
                                       <div className="text-md-right ms-auto">
                                           <PaginationListStandalone
                                               {...paginationProps}
                                           />
                                       </div>
                                    </Col>
                                </Row>
                                <audio ref={audioPlayer} src={NotificationSound} />
                               </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </CardBody>
                        {
                            loading && forceLoading &&
                            <div style={{zIndex: 5100}} className="bg-white opacity-75 w-100 h-100 text-center position-absolute top-0">
                                <Spinner className="spinner-border text-info" style={{marginTop: '250px'}}  role="status">
                                </Spinner>
                            </div>
                        }
                    </Card>
                    {gameModal ? (
                        <SweetAlert

                            title=""
                            showCancel
                            showConfirm={false}
                            cancelBtnText={props.t("Cancel")}
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                //setGameModal(false)
                            }}
                            onCancel={() => {
                                setGameModal(false)
                            }}
                        >
                            <h4>{gameModalData.competition}
                                <i onClick={ ()=> setCompetitionFilter(gameModalData.competition)}
                                                               className="ms-2 mdi mdi-magnify search-icon clr-green cursor-pointer"></i>
                                <i onClick={ ()=> setFavoriteFilter(gameModalData.home + ' - ' + gameModalData.away)}
                                   className={"ms-2 mdi mdi-star" + (checkFavoriteGame(gameModalData.home + ' - ' + gameModalData.away, true) ? '' : '-outline') + " clr-red cursor-pointer"}></i>
                            </h4>
                            {
                                !gameClosed &&   <h5>{gameModalData.status === 'HT' ? 'HT' : <Timer initialSeconds={gameModalData.time_game} halfTime={gameModalData.status} ></Timer>}</h5>
                            }
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: defaultTabModal === "1" })}
                                        onClick={() => {
                                            defaultToggleModal("1");
                                        }}
                                    >
                                    <span className="d-none d-sm-block">{props.t("Summary")}</span>
                                    </NavLink>
                                </NavItem>
                                {
                                    !gameClosed &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: defaultTabModal === "2" })}
                                            onClick={() => {
                                                defaultToggleModal("2");
                                            }}
                                        >
                                            <span className="d-none d-sm-block">{props.t("Bet")}</span>
                                        </NavLink>
                                    </NavItem>
                                }
                            </Nav>
                            <TabContent activeTab={defaultTabModal} className="p-3 text-muted">
                                <TabPane tabId="1">
                                    <table className="w-100">
                                        <tbody>
                                        <tr className="bold-text border-bottom-1">
                                            <td className="w-25">
                                                <i onClick={ ()=> filterGoalsHomeExact(gameModalData.home_goals, gameModalData.away_goals)}
                                                                    className="mdi mdi-eye-check clr-orange-500  cursor-pointer"></i>
                                            </td>
                                            <td className={ 'w-375 border-right-1' + gameModalData.home_score_status}>
                                                <Row>
                                                    <Col>
                                                        {gameModalData.home}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <i onClick={ ()=> filterTeam(gameModalData.home, 1)}
                                                            className="mdi mdi-eye-check text-primary cursor-pointer"></i>
                                                        {' '}
                                                        <a rel="noreferrer" className="ms-2 mdi mdi-table text-black" target="_blank"
                                                           href={String(process.env.REACT_APP_GOOGLE_TABLE_URL) + '?q=' +
                                                               gameModalData.home +  (gameModalData.competition != 'Other Football' ? ' ' + gameModalData.competition : '')}>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </td>
                                            <td className={gameModalData.away_score_status}>
                                                <Row>
                                                    <Col>
                                                        {gameModalData.away}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <i onClick={ ()=> filterTeam(gameModalData.away, 1)}
                                                           className="mdi mdi-eye-check text-primary cursor-pointer"></i>
                                                        {' '}
                                                        <a rel="noreferrer" className="ms-2 mdi mdi-table text-black" target="_blank"
                                                           href={String(process.env.REACT_APP_GOOGLE_TABLE_URL) + '?q=' +
                                                               gameModalData.away +  (gameModalData.competition != 'Other Football' ? ' ' + gameModalData.competition : '')}>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr className="border-bottom-1">
                                            <td className="text-end bold-text border-right-1 pe-1">{props.t("Score")}</td>
                                            <td className={ 'border-right-1' + gameModalData.home_score_status}>{gameModalData.home_score}</td>
                                            <td className={gameModalData.away_score_status}>{gameModalData.away_score}</td>
                                        </tr>
                                        <tr className="border-bottom-1">
                                            <td className="text-end bold-text border-right-1 pe-1">{props.t("Goals")}</td>
                                            <td className="border-right-1">{gameModalData.home_goals}</td>
                                            <td>{gameModalData.away_goals}</td>
                                        </tr>
                                        <tr className="border-bottom-1">
                                            <td className="text-end bold-text border-right-1 pe-1">{props.t("Corners")}</td>
                                            <td className={ 'border-right-1' + gameModalData.home_corner_status}>{gameModalData.home_corner}</td>
                                            <td className={gameModalData.away_corner_status}>{gameModalData.away_corner}</td></tr>
                                        <tr className="border-bottom-1">
                                            <td className="text-end bold-text border-right-1 pe-1">{props.t("Shots in/out")}</td>
                                            <td className={'border-right-1 ' + gameModalData.home_shots_status}>{gameModalData.home_shots}</td>
                                            <td className={gameModalData.away_shots_status}>{gameModalData.away_shots}</td>
                                        </tr>
                                        <tr className="border-bottom-1">
                                            <td className="text-end bold-text border-right-1 pe-1">{props.t("Possession")}</td>
                                            <td className={ 'border-right-1' + gameModalData.home_possession_status}>{gameModalData.home_possession}%</td>
                                            <td className={gameModalData.away_possession_status}>{gameModalData.away_possession}%</td>
                                        </tr>
                                        <tr className="border-bottom-1">
                                            <td className="text-end bold-text border-right-1 pe-1">{props.t("Red card")}</td>
                                            <td className="border-right-1">
                                                {gameModalData.home_red_card > 0 &&
                                                    <React.Fragment>
                                                        {[...Array(gameModalData.home_red_card)].map((x, i) =>
                                                            <i key={'card_modal_h2ome_' + i} className="mdi mdi-checkbox-blank clr-red"></i>
                                                        )}
                                                    </React.Fragment>
                                                }
                                            </td>
                                            <td>
                                                {gameModalData.away_red_card > 0 &&
                                                    <React.Fragment>
                                                        {[...Array(gameModalData.away_red_card)].map((x, i) =>
                                                            <i key={'card_modal_away_' + i} className="mdi mdi-checkbox-blank clr-red"></i>
                                                        )}
                                                    </React.Fragment>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="border-bottom-1">
                                            <td className="text-end bold-text border-right-1 pe-1">{props.t("Yellow card")}</td>
                                            <td className={ 'border-right-1' + (gameModalData.home_yellow_card > 0 ? ' bg-soft-secondary' : '')}>
                                                {gameModalData.home_yellow_card > 0 &&
                                                    <React.Fragment>
                                                        {[...Array(gameModalData.home_yellow_card)].map((x, i) =>
                                                            <i key={'card_modal_home_' + i} className="mdi mdi-checkbox-blank clr-yellow"></i>
                                                        )}
                                                    </React.Fragment>
                                                }
                                            </td>
                                            <td className={gameModalData.away_yellow_card > 0 ? ' bg-soft-secondary' : ''}>
                                                {gameModalData.away_yellow_card > 0 &&
                                                    <React.Fragment>
                                                        {[...Array(gameModalData.away_yellow_card)].map((x, i) =>
                                                            <i key={'card_modal_away_' + i} className="mdi mdi-checkbox-blank clr-yellow"></i>
                                                        )}
                                                    </React.Fragment>
                                                }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </TabPane>
                                {
                                    !gameClosed &&
                                    <TabPane tabId="2">
                                        {
                                            <Row className="border-bottom-1 mb-2 pb-2">
                                                <Col>
                                                    <a rel="noreferrer" target="_blank" href={String(process.env.REACT_APP_SKYBET_EVENT_URL) + gameModalData.game_event_id}>
                                                        <img  width="64px" src={process.env.REACT_APP_SKYBET_LOGO} alt={''}/>
                                                    </a>
                                                </Col>
                                                <Col>
                                                    <a rel="noreferrer" target="_blank" href={String(process.env.REACT_APP_WILLIAM_HILL_EVENT_URL) + gameModalData.game_william_hill}>
                                                        <img  width="64px" height={"64px"} src={process.env.REACT_APP_WILLIAM_HILL_LOGO} alt={''}/>
                                                    </a>
                                                </Col>
                                            </Row>
                                        }
                                        <table className="w-100">
                                            <tbody>
                                            <tr className="bold-text border-bottom-1">
                                                <td className="w-25"></td>
                                                <td className={ 'w-375 border-right-1' + gameModalData.home_score_status}>{gameModalData.home}</td>
                                                <td className={gameModalData.away_score_status}>{gameModalData.away}</td>
                                            </tr>
                                            <tr className="border-bottom-1">
                                                <td className="text-end bold-text border-right-1 pe-1">{props.t("Score")}</td>
                                                <td className={ 'border-right-1' + gameModalData.home_score_status}>{gameModalData.home_score}</td>
                                                <td className={gameModalData.away_score_status}>{gameModalData.away_score}</td>
                                            </tr>
                                            <tr className="border-bottom-1">
                                                <td className="text-end bold-text border-right-1 pe-1">{props.t("Goals")}</td>
                                                <td className="border-right-1">{gameModalData.home_goals}</td>
                                                <td>{gameModalData.away_goals}</td>
                                            </tr>
                                            <tr className="border-bottom-1">
                                                <td className="text-end bold-text border-right-1 pe-1">{props.t("Initial odds")}</td>
                                                <td className="border-right-1">{gameModalData.home_initial_odd}</td>
                                                <td>{gameModalData.away_initial_odd}</td>
                                            </tr>
                                            <tr className="border-bottom-1">
                                                <td className="text-end bold-text border-right-1 pe-1">{props.t("Current odds")}</td>
                                                <td className="border-right-1">{gameModalData.home_current_odd}</td>
                                                <td>{gameModalData.away_current_odd}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </TabPane>
                                }
                            </TabContent>
                        </SweetAlert>
                    ) : null}
                </Container>
            </div>
    </React.Fragment>
    );
};
export default withTranslation()(GamesTable);
